<!-- <template>
  <b-card>
    <b-form>
      <b-row>
        <b-col md="7">
          <b-form-group
            label="First Name"
            label-for="account-First-Name"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-First-Name"
                v-model="FirstName"
                name="FirstName"
                :type="FirstNameText"
                placeholder="First Name"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="7">
          <b-form-group
            label="Last Name"
            label-for="account-Last-Name"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-Last-Name"
                v-model="LastName"
                name="LastName"
                :type="LastNameText"
                placeholder="Last Name"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="7">
          <b-form-group
            label="Email"
            label-for="account-Email"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-Email"
                v-model="Email"
                name="Email"
                :type="EmailText"
                placeholder="Email"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
		<b-col md="7">
          <b-form-group
            label="PhoneNumber"
            label-for="account-Phone"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-Phone"
                v-model="Phone"
                name="Phone"
                :type="PhoneNum"
                placeholder="Phone Number"
              />
            </b-input-group>
		</b-form-group>
    </b-col>
        <b-col cols="12">
          <b-button
		  	v-b-modal.modal-1-center
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
          >
            Continue
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
		<b-modal centered ok-title="Confirm" ok-only id="modal-1-center" title="Indian Sender Details">
			<b-form class ="mx-2">
				<b-row>
					<b-col><img width="180" src="./sample1.png"/>
						<b-row>
							<b-col>
								<b-button size="sm"
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="primary"
								class="mt-1"
								>
									Re-take
          						</b-button>
							</b-col>
							<b-col>
								<b-button size="sm"
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								variant="outline-secondary"
								class="mt-1"
								>
								Upload
								</b-button>
							</b-col>
						</b-row>
					</b-col>
					<b-col><img width="175" src="./sample2.png"/>
						<b-row>
							<b-col>
								<b-button size="sm"
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="primary"
								class="mt-1"
								>
									Re-take
          						</b-button>
							</b-col>
							<b-col>
								<b-button size="sm"
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								variant="outline-secondary"
								class="mt-1"
								>
								Upload
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-form-group
						label="PhoneNumber"
						label-for="account-Phone"
						>
						<b-input-group class="input-group-merge">
						<b-form-input
							id="account-Phone"
							v-model="Phone"
							name="Phone"
							:type="PhoneNum"
							placeholder="Phone Number"
						/>
						</b-input-group>
					</b-form-group>
				</b-row>

			</b-form>
		</b-modal>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,BModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Image from '@/views/components/image/Image.vue'

export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    Image
},
  directives: {
    Ripple,
  },
  data() {
    return {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      FirstNameText: 'text',
      LastNameText: 'text',
      EmailText: 'text',
	  PhoneNum: 'Number'
    }
  }
}
</script> -->
